import { AxiosResponse } from 'axios';
import { CreatDeviceGroupDto } from 'dto/creat-device-group.dto';
import { DeviceGroupResponseDto } from 'dto/device-group-response.dto';
import { MembersMineInviteDto } from 'dto/members-mine-invite.dto';
import { RegisterDto } from 'dto/register.dto';
import { ResetPasswordDto, SendEmailDto } from 'dto/reset-password.dto';
import { SignInDto } from 'dto/signin.dto';
import { UpdateUserPasswordCheckDto } from 'dto/update-user-password-check.dto';
import { User } from 'entities/user.entity';
import { GetDevices } from 'models/get-devices';
import { GetMembers } from 'models/get-member';
import { Oauth2 } from 'models/oauth2';
import React, { useEffect, useState } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { getItem, setItem } from 'utils/localStorageHelper';
import { useConfig } from './ConfigProvider';

const AuthContext = React.createContext<{
  token: string;
  userId: string;
  password: string;
  user: User;
  signInByUser: (params: SignInDto) => void;
  signUpByUser: (params: RegisterDto) => Promise<AxiosResponse>;
  sendEmailByUser: (params: SendEmailDto) => Promise<AxiosResponse>;
  resetPasswordByUser: (params: ResetPasswordDto, token: string) => Promise<AxiosResponse>;
  signInToRemoteDevice: (deviceId: string) => Promise<AxiosResponse<Oauth2>>;
  storageClearThenGoBack: () => void;
  logout: () => void;
  getUser: () => void;
  putUserUpdateMe: (namePassword: UpdateUserPasswordCheckDto) => Promise<AxiosResponse>;
  deleteDevice: (deviceId: string) => Promise<AxiosResponse>;
  getCbtFirmwareBySerialNumber: (deviceId: string, remoteToken: string) => Promise<AxiosResponse>;
  postCbtFirmwareBySerialNumber: (deviceId: string, remoteToken: string) => Promise<AxiosResponse>;
  getMembersByLeaderId: (id: number) => Promise<AxiosResponse<GetMembers[]>>;
  deleteMemberByEmail: (memberEmai: string) => Promise<AxiosResponse>;
  postMemberInvite: (data: MembersMineInviteDto) => Promise<AxiosResponse>;
  getMyDevices: () => Promise<AxiosResponse<GetDevices[]>>;
  getAssignedDevices: (userId: number) => Promise<AxiosResponse<GetDevices[]>>;
  getDeviceGroupsMine: () => Promise<AxiosResponse<DeviceGroupResponseDto[]>>;
  deleteDeviceGroupsMineById: (id: number) => Promise<AxiosResponse>;
  postDeviceGroups: (data: CreatDeviceGroupDto) => Promise<AxiosResponse>;
  putDeviceGroups: (id: number, data: CreatDeviceGroupDto) => Promise<AxiosResponse>;
}>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { cloudUrl, cloudApiUrl } = useConfig();
  const storageToken = getItem('access_token') ?? '';
  const storageUserId = getItem('userId') ?? '';
  const [token, setToken] = useState<string>(storageToken);
  const [userId, setUserId] = useState<string>(storageUserId);
  const [password, setPassword] = useState<string>('');
  const [user, setUser] = useState<User>({} as User);

  useEffect(() => {
    if (cloudApiUrl !== undefined) {
      axiosInstance.interceptors.response.use(
        // Success actions
        undefined,
        async (error: any) => {
          switch (error.response.status) {
            case 401:
              const newToken = await refreshAccessToken();
              // 使用新 Token 重新發送原始請求
              error.config.headers.Authorization = `Bearer ${newToken}`;
              return axiosInstance.request(error.config);
            default:
              break;
          }
          return Promise.reject(error);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudApiUrl]);

  useEffect(() => {
    if (token !== '' && cloudApiUrl !== undefined) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, cloudApiUrl]);

  const signInByUser = (params: SignInDto) => {
    setPassword(params.password);
    return axiosInstance.post<Oauth2>(`${cloudApiUrl}/api/oauth2`, params).then((response: AxiosResponse<Oauth2>) => {
      const { access_token, username, refresh_token } = response.data;
      setItem('access_token', access_token);
      setItem('refresh_token', refresh_token);
      setToken(access_token);
      setItem('userId', username);
      setUserId(username);
    });
  };

  const signUpByUser = (params: RegisterDto) => {
    return axiosInstance.post(`${cloudApiUrl}/api/users/register`, params);
  };

  const sendEmailByUser = (params: SendEmailDto) => {
    return axiosInstance.put(`${cloudApiUrl}/api/users/resetPassword`, params);
  };

  const resetPasswordByUser = (params: ResetPasswordDto, token: string) => {
    return axiosInstance.put(`${cloudApiUrl}/api/verification/reset?token=${token}`, params);
  };

  const signInToRemoteDevice = (deviceId: string) =>
    axiosInstance.get<Oauth2>(`${cloudApiUrl}/api/oauth2/${deviceId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  const logout = () => {
    axiosInstance
      .delete(`${cloudApiUrl}/api/oauth2/${token}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        storageClearThenGoBack();
      });
  };

  const storageClearThenGoBack = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace('/');
  };

  const refreshAccessToken = async () => {
    try {
      if (getItem('refresh_token') !== null) {
        const response = await axiosInstance.get(`${cloudApiUrl}/api/oauth2/refreshToken/${getItem('refresh_token')}`);
        const newToken = response.data.access_token;
        setItem('access_token', newToken);
        setToken(newToken);
        return newToken;
      }
    } catch (error) {
      console.error('Failed to refresh access token:', error);
      logout();
    }
  };

  const getUser = () =>
    axiosInstance
      .get(`${cloudApiUrl}/api/users/me`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<User>) => {
        setUser(response.data);
      })
      .catch(() => {});

  const putUserUpdateMe = (namePassword: UpdateUserPasswordCheckDto): Promise<AxiosResponse> =>
    axiosInstance.put(`${cloudApiUrl}/api/users/userUpdate/me`, namePassword, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  const deleteDevice = (deviceId: string): Promise<AxiosResponse> =>
    axiosInstance.delete(`${cloudApiUrl}/api/devices/removeDevice/${deviceId}/mine`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  const getCbtFirmwareBySerialNumber = (deviceId: string, remoteToken: string): Promise<AxiosResponse> =>
    axiosInstance.get(`https://${deviceId}.${cloudUrl}/owgw/firmware/${deviceId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${remoteToken}`,
      },
    });

  const postCbtFirmwareBySerialNumber = (deviceId: string, remoteToken: string): Promise<AxiosResponse> =>
    axiosInstance.post(
      `https://${deviceId}.${cloudUrl}/owgw/firmware/${deviceId}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${remoteToken}`,
        },
      }
    );

  const getMembersByLeaderId = (leaderId: number): Promise<AxiosResponse<GetMembers[]>> =>
    axiosInstance.get(`${cloudApiUrl}/api/users/${leaderId}/members`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  const deleteMemberByEmail = (memberEmail: string): Promise<AxiosResponse> =>
    axiosInstance.delete(`${cloudApiUrl}/api/members/${memberEmail}/mine`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  const postMemberInvite = (data: MembersMineInviteDto): Promise<AxiosResponse> =>
    axiosInstance.post(`${cloudApiUrl}/api/members/mine/invite`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  const getMyDevices = (): Promise<AxiosResponse<GetDevices[]>> => {
    return axiosInstance.get(`${cloudApiUrl}/api/devices/mine/withRelationData`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const getAssignedDevices = (userId: number): Promise<AxiosResponse<GetDevices[]>> => {
    return axiosInstance.get(`${cloudApiUrl}/api/devices/user/${userId}/withRelation`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const getDeviceGroupsMine = (): Promise<AxiosResponse<DeviceGroupResponseDto[]>> => {
    return axiosInstance.get(`${cloudApiUrl}/api/device-groups/mine`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const deleteDeviceGroupsMineById = (id: number): Promise<AxiosResponse> =>
    axiosInstance.delete(`${cloudApiUrl}/api/device-groups/${id}/mine`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  const postDeviceGroups = (data: CreatDeviceGroupDto): Promise<AxiosResponse> =>
    axiosInstance.post(`${cloudApiUrl}/api/device-groups`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  const putDeviceGroups = (id: number, data: CreatDeviceGroupDto): Promise<AxiosResponse> =>
    axiosInstance.put(`${cloudApiUrl}/api/device-groups/${id}/mine`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  return (
    <AuthContext.Provider
      value={{
        token,
        userId,
        password,
        user,
        logout,
        signInByUser,
        signUpByUser,
        sendEmailByUser,
        resetPasswordByUser,
        signInToRemoteDevice,
        storageClearThenGoBack,
        getUser,
        putUserUpdateMe,
        deleteDevice,
        getCbtFirmwareBySerialNumber,
        postCbtFirmwareBySerialNumber,
        getMembersByLeaderId,
        deleteMemberByEmail,
        postMemberInvite,
        getMyDevices,
        getAssignedDevices,
        getDeviceGroupsMine,
        deleteDeviceGroupsMineById,
        postDeviceGroups,
        putDeviceGroups,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
