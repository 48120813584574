import axios from 'axios';
import axiosRetry from 'axios-retry';

export const AUTH_EXPIRED_TOKEN_CODE = 9;
export const AUTH_INVALID_TOKEN_CODE = 8;

const axiosInstance = axios.create();
axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
});

axiosInstance.defaults.timeout = 160000;
axiosInstance.defaults.headers.get.Accept = 'application/json';
axiosInstance.defaults.headers.post.Accept = 'application/json';

export default axiosInstance;
