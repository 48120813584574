import remove from 'assets/remove.svg';
import React, { useEffect, useRef, useState } from 'react';

interface ToastContextType {
  addToast: (params: ToastType) => void;
}
interface ToastsProviderType {
  children: React.ReactNode;
}
interface ToastType {
  icon?: string;
  title?: string;
  message: string;
  duration?: number; // defultValue = 3000
  color?:
    | ''
    | 'bg-green-200'
    | 'bg-rose-200'
    | 'bg-toast-error-50'
    | 'bg-toast-info-50'
    | 'bg-toast-success-50'
    | 'bg-toast-warning-50'
    | 'bg-toast-primary-200'
    | 'bg-cyfi-error'
    | 'bg-cyfi-info'
    | 'bg-cyfi-success'
    | 'bg-cyfi-warning'
    | 'bg-cyfi-primary'; // defultValue = ''
  text?: 'text-toast-error-800' | 'text-toast-info-800' | 'text-toast-success-800' | 'text-toast-warning-800' | 'text-cyfi-btn';
  svg?: 'toastIcon-warning' | 'toastIcon-success' | 'toastIcon-error' | 'toastIcon-info' | 'cyfi-svg';
}

const Toast = ({ title, message, duration, color, icon, text, svg }: ToastType) => {
  const [show, setShow] = useState<boolean>(true);
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => () => clearTimeout(timeout.current), []);

  useEffect(() => {
    if (show === true && duration) {
      setAutohide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, duration]);

  const close = () => {
    clearTimeout(timeout.current);
    setShow(false);
  };

  const setAutohide = () => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setShow(false);
    }, duration);
  };

  return (
    <>
      {show && (
        <div className={`flex w-full max-w-md p-2 text-gray-500 rounded shadow mb-4 ${color}`}>
          <div className="pt-1 mr-4">
            {icon && (
              <img className={`w-6 h-6 mr-4 ${svg}`} src={icon} alt="" onError={(e) => ((e.target as HTMLTextAreaElement).style.display = 'none')} />
            )}
          </div>
          <div className={`flex-col items-start flex justify-center ${text}`}>
            {title && <p className="text-sm font-semibold">{title}</p>}
            <p className="text-xs font-normal">{message}</p>
          </div>
          <button className="btn btn-ghost ml-auto hover:bg-transparent" onClick={close}>
            <img src={remove} className={`w-4 h-4 ${svg}`} alt="close" />
          </button>
        </div>
      )}
    </>
  );
};

const ToastContext = React.createContext<ToastContextType>(null!);

export const ToastProvider = ({ children }: ToastsProviderType) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);

  const addToast = ({ title, message, duration = 3000, color = '', icon, text, svg }: ToastType) => {
    setToasts([...toasts, { title, message, duration, color, icon, text, svg }]);
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      {/* toast z-index 需要比 modal z-index:999 高 */}
      <div className="toast toast-center flex items-center w-full max-w-xs" style={{ maxWidth: '33vw', zIndex: 1000 }}>
        {toasts.map((toast, index) => {
          return (
            <Toast
              key={index}
              duration={toast.duration}
              color={toast.color}
              title={toast.title}
              message={toast.message}
              icon={toast.icon}
              text={toast.text}
              svg={toast.svg}
            ></Toast>
          );
        })}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => React.useContext(ToastContext);
