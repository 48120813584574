import RequireAuth from 'components/RequireAuth';
import { AuthProvider } from 'contexts/AuthProvider';
import { ConfigProvider } from 'contexts/ConfigProvider';
import { LoadingMaskProvider } from 'contexts/LoadingMaskProvider';
import { ModalProvider } from 'contexts/ModalProvider';
import { ToastProvider } from 'contexts/ToastProvider';
import Loading from 'pages/Loading';
import { lazy, Suspense } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

const Layout = lazy(() => import('./layout'));
const Device = lazy(() => import('./pages/Device'));
const Member = lazy(() => import('./pages/Member'));
const Profile = lazy(() => import('./pages/Profile'));
const NoMatch = lazy(() => import('./pages/NoMatch'));
const Blank = lazy(() => import('./pages/Blank'));
const Login = lazy(() => import('./pages/Login'));

const App = () => {
  const routes: RouteObject[] = [
    {
      path: '/',
      element: (
        <Suspense fallback={<Loading />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        { index: true, element: <Device tab={0} /> },
        {
          path: '/devices',
          element: <Blank />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Loading />}>
                  <Device tab={0} />
                </Suspense>
              ),
            },
            {
              path: '/devices/devices',
              element: (
                <Suspense fallback={<Loading />}>
                  <Device tab={0} />
                </Suspense>
              ),
            },
            {
              path: '/devices/device-groups',
              element: (
                <Suspense fallback={<Loading />}>
                  <Device tab={1} />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: '/members',
          element: <Blank />,
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Loading />}>
                  <Member tab={0} />
                </Suspense>
              ),
            },
            {
              path: '/members/members',
              element: (
                <Suspense fallback={<Loading />}>
                  <Member tab={0} />
                </Suspense>
              ),
            },
            {
              path: '/members/member-groups',
              element: (
                <Suspense fallback={<Loading />}>
                  <Member tab={1} />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: '/profile',
          element: (
            <Suspense fallback={<Loading />}>
              <Profile />
            </Suspense>
          ),
        },
        { path: '*', element: <NoMatch /> },
      ],
    },
    {
      path: '/register',
      element: (
        <Suspense fallback={<Loading />}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: '/api/users/sendEmail',
      element: (
        <Suspense fallback={<Loading />}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: 'portal.sonicfi-networks.com/reset_password',
      element: (
        <Suspense fallback={<Loading />}>
          <Login />
        </Suspense>
      ),
    },
  ];
  const element = useRoutes(routes);
  return (
    <ConfigProvider>
      <LoadingMaskProvider>
        <ToastProvider>
          <AuthProvider>
            <ModalProvider>{element}</ModalProvider>
          </AuthProvider>
        </ToastProvider>
      </LoadingMaskProvider>
    </ConfigProvider>
  );
};

export default App;
